:root {
  --primary: #012b73;
  --secondary: #26c3f6;
  --contrast-color: #f1f7ff;
  --contrast-secondary: #d3f4ff;
  --main-green: #11c744;
  --secondary-green: #cafad7;
  --main-red: #ff0000;
  --light-red: #ff7272;
  --dark-thin-red: #fde6e6;
  --black: #000000;
  --light-black: #333;
  --white: #ffffff;
  --thin-white: #f0fcff;
  --olive: #19999f;
  --gray: #ededed;
  --grey: #808080;
  --light-gray: #e8e8e8;
  --dark-thin-gray: #f3f7f8;
  --dark--blue: #80bdff;
  --light-blue: #e5e3ef;
}

*,
html,
body {
  box-sizing: 0;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*common css */
.text--red {
  color: var(--main-red) !important;
}
.icon {
  width: 1rem;
  height: 1rem;
}
.custom-linear-loader-row {
  z-index: 3;
  top: -1px;
  height: 1px;
  border-radius: 5px;
}
.BarLoader {
  color: var(--primary);
}
.bg--olive {
  background: var(--olive);
}
.bg--blue {
  background: var(--primary);
}
.bg--primary {
  background: var(--primary);
}
.bg--green {
  background: var(--main-green);
  border: 1px solid var(--secondary-green);
}
.bg--red {
  background-color: var(--light-red);
  border: 1px solid var(--dark-thin-red);
}
.bg--light-blue {
  background: var(--contrast-color);
  border: 1px solid var(--contrast-secondary);
  border-radius: 8px;
}
.gutter--xs {
  gap: 0.25rem;
}
.gutter--sm {
  gap: 0.5rem;
}
.gutter-1 {
  gap: 0.25rem;
}
.gutter--1 {
  gap: 1rem;
}
.heading--md {
  font-size: 1.3rem;
  font-weight: 600;
}
.heading--sm {
  font-size: 1rem;
  font-weight: 500;
}
.text--xs {
  font-size: 0.7rem;
}
.font--xs {
  font-size: 0.8rem;
}
.font--sm {
  font-size: 1rem;
}
.font--md {
  font-size: 1.15rem;
}
.font-600 {
  font-weight: 600;
}
.font-500 {
  font-weight: 500;
}
.w-max-150 {
  max-width: 150px;
}
.w-max-240 {
  max-width: 240px;
}
.w-max-300 {
  max-width: 300px;
}
.w-max-320 {
  max-width: 320px;
}
.pointer {
  cursor: pointer;
}
/* Form */
.form_event_inner {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.w-max-525 {
  max-width: 525px;
  min-width: 525px;
}
.form-label {
  color: var(--black);
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem !important;
}
.form-block {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 12px;
}
.form-block--py-s {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.form-block--px-s {
  padding-left: 1rem;
  padding-right: 1rem;
}
.form-control:focus {
  background-color: var(--white);
  border-color: var(--dark--blue);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-select:focus {
  background-color: var(--white);
  border-color: var(--dark--blue);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
input[type="file"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: none;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: var(--light-black);
  background-color: var(--white);
  background-clip: padding-box;
  border: 0.5px solid var(--light-gray);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 38px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 8px;
}
/* .form-check-label {
  font-size: 0.8rem;
} */
/* Navbar */
#root {
  background-color: var(--contrast-color);
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 0px);
}
#page_container {
  padding-top: 70px;
}
#header .navbar {
  background: var(--white);
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 0px rgba(0, 0, 0, 0.08);
}
#header .navbar-nav a {
  padding: 8px 13px;
  margin-right: 5px;
  text-decoration: none;
  color: var(--black);
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
}
#header .navbar-nav a.dropdown-item.active,
#header .navbar-nav a.activeClassName {
  color: var(--white) !important;
  background: var(--primary);
  border-radius: 4px;
  display: flex;
  text-decoration: none;
}
.navbar-brand img {
  width: 40px;
}
/* Buttons */
.btn {
  font-size: 0.8rem;
  font-weight: 500;
  min-width: 90px;
}
.btn-action {
  min-width: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 5px;
  cursor: pointer;
}
.btn-action i {
  font-size: 14px;
}
.btn-group-sm > .btn,
.btn-sm {
  line-height: 18px;
  padding: 3px 8px;
}
.btn-primary {
  background: var(--contrast-color) !important;
  color: var(--black) !important;
  border-color: var(--contrast-color) !important;
}
.btn--primary:hover {
  background-color: var(--secondary);
  color: var(--white);
}
.btn-grey {
  background-color: var(--gray);
}
.btn-grey:hover {
  background-color: var(--gray);
  color: var(--black) !important;
}
.btn--primary {
  background-color: var(--primary);
  color: var(--white);
}
.btn--dark-blue {
  background: var(--secondary);
  border-radius: 5px;
  color: var(--white);
}
.btn--dark-blue:hover {
  background: var(--secondary);
  color: var(--white);
}
/* Pagination */
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background: var(--secondary);
  border-color: var(--contrast-color);
  color: var(--white) !important;
}
.pagingWrap .wrapSpan {
  line-height: 1.7;
  font-weight: bold;
  font-size: 12px;
}
.pagingWrap .wrapSpan .form-control {
  width: 50px;
  height: 30px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
}
.pagination button {
  min-width: 1.8rem;
  width: 1.8rem;
  height: 1.8rem;
}
.pagination button:disabled,
.pagination button[disabled] {
  color: var(--light-black);
  background: var(--light-gray);
  border-radius: 8px;
}
.pagination .btn--pagination-active {
  background-color: var(--secondary);
  color: var(--white);
}
/* Dropdown */
.dropdown_dot {
  width: 1.8rem;
  position: inherit;
}
.dropdown .dropdown-menu {
  padding: 1em 0.4em;
  border: none;
  min-width: 17.8rem;
  max-width: 18rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
}
.dropdown .dropdown-menu .dropdown-item {
  padding: 10px 10px;
  font-size: 0.8rem;
  color: var(--light-black) !important;
  cursor: pointer;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  background: var(--contrast-color);
  border-radius: 5px;
}
.dropdown_dot .btn-action:after {
  display: none;
}
.dropdown .dropdown-menu .dropdown-item span {
  font-size: 0.8rem;
  margin-left: 5px;
}
/* Table */
.page_heading h3 .title {
  padding-right: 10px;
  color: var(--light-black);
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  color: var(--black);
  font-size: 12px;
}
.table tbody tr td {
  font-weight: 500;
  white-space: nowrap;
}
.main-section {
  max-height: calc(100vh - 1.5rem);
  min-height: calc(100vh - 1.5rem);
}
.main-section1 {
  max-height: calc(100vh - 3rem);
  min-height: calc(100vh - 3rem);
}
.full-table-height .table-scroll {
  max-height: calc(100vh - 7.9rem);
  min-height: calc(100vh - 7.9rem);
}
.table-scroll {
  min-height: calc(100vh - 10.9rem);
  max-height: calc(100vh - 10.9rem);
}
.table_custom {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-top: -0.5rem;
}
.table_custom th {
  border: none !important;
  color: var(--white) !important;
  background-color: var(--primary);
  position: sticky;
  top: 0;
  text-wrap: nowrap;
}
.table_custom th:first-child {
  border-radius: 5px 0 0 5px;
}
.table_custom th:last-child {
  border-radius: 0 5px 5px 0;
}
.table_custom td {
  padding: 6px 6px !important;
  background: var(--white);
  border: none !important;
  vertical-align: middle;
}
.table_custom td:first-child {
  border-radius: 5px 0 0 5px;
}
.table_custom td:last-child {
  border-radius: 0px 5px 5px 0px;
}
.table-responsive .bg-white {
  min-height: 85vh;
}
.lower_section {
  padding: 15px;
}
.content-header img {
  width: 3rem;
}
.reload_icon {
  width: 25px !important;
  min-width: 20px;
  border-radius: 50%;
  height: 25px;
  padding: 0;
}
.reload_icon:disabled {
  cursor: not-allowed;
  background-color: var(--gray) !important;
  border-color: var(--gray) !important;
}
.page_count_btn {
  margin: 0px 4px;
  width: 100% !important;
  min-width: 25px;
}
.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
.sliderFormContent {
  width: 100% !important;
  max-width: 700px;
}
.addplanslider {
  max-width: 70vw;
}
.ratecardslider {
  width: 100% !important;
  max-width: 70vw;
  background-color: var(--contrast-color);
}
.ratecardslider .offcanvas-header {
  border-bottom: 1px dashed var(--grey);
}
.addplanslider .offcanvas-header {
  border-bottom: 1px dashed var(--grey);
}
.sliderFormContent .offcanvas-header {
  border-bottom: 1px dashed var(--grey);
}
.sliderFormContent .offcanvas-header .offcanvas-title {
  font-size: 18px;
  color: var(--black);
}
.SliderFormBody {
  height: calc(100vh - 149px);
  margin-bottom: 15px;
  overflow: hidden;
  overflow-y: auto;
}
/* Filter */
.filter-header p {
  font-size: 15px;
  color: var(--black);
}
.filter-body {
  height: calc(100vh - 181px);
  margin-bottom: 15px;
  overflow-y: auto;
}
.details-heading {
  background: var(--contrast-color);
  border-radius: 8px;
  color: var(--light-black);
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.bg--light-white {
  background: var(--white);
  border: 1px solid var(--light-gray);
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  border-radius: 8px;
  min-width: 33px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.chats__list {
  min-height: calc(100vh - 1.5rem);
  max-height: calc(100vh - 1.5rem);
  max-width: 13rem;
  min-width: 13rem;
  border-radius: 12px;
}
.chats__list a {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--black) !important;
  padding-top: 5px;
  padding: 0.4em 0.4em;
  border-left: 4px solid transparent;
  border-bottom: 1px solid var(--light-gray);
}
.chats__list h3 {
  font-size: 0.9rem;
  color: var(--primary);
  font-weight: 600;
}
.chats__list_active {
  background: var(--thin-white);
  border-left: 4px solid var(--secondary) !important;
  padding: 0.4em 0.4em;
}
.chats__list a:hover {
  background: var(--thin-white);

  border-left: 4px solid var(--secondary);
  padding: 0.4em 0.4em;
}
.call_sec1 .chat_rightbar {
  min-height: calc(100vh - 1.5rem);
  max-height: calc(100vh - 1.5rem);
}
.call_sec1 .chat_rightbar span {
  font-size: 0.8rem;
}
.audio-file-td audio {
  width: 420px;
}
.dropdown-toggle {
  border-color: transparent !important;
}
.like {
  background: var(--dark-thin-red);
  outline: 2px solid rgba(255, 197, 197, 0.5);
  border-radius: 50%;
  height: 2.1rem;
  width: 2.1rem;
}
#style-2::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  background-color: var(--light-gray);
}
#style-2::-webkit-scrollbar {
  width: 8px;
  padding-left: 5px;
  background-color: var(--light-gray);
}
#style-2::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.perGB {
  font-size: 0.8rem;
  height: 40px;
  width: 50px;
}
.css-13cymwt-control {
  font-size: 13px !important;
}
.Upload-file {
  background-color: var(--light-blue);
  border-radius: 5px;
}
.upload-btn-wrapper img {
  border-radius: 8px;
  height: 115px;
  max-width: 125px;
}
.custom-dropdown-input .PhoneInput {
  display: flex;
}
.custom-dropdown-input .PhoneInput .PhoneInputInput {
  border: none;
  width: 100%;
}
.custom-dropdown-input .PhoneInput .PhoneInputInput:focus {
  outline: none;
  border: none;
}
.custom-dropdown-input .PhoneInput--disabled {
  background-color: var(--light-blue);
  cursor: not-allowed;
}
.custom-dropdown-input .PhoneInput--disabled .PhoneInputInput {
  cursor: not-allowed;
}
.custom-dropdown-input .PhoneInputCountry {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.custom-dropdown-input .PhoneInputCountryIcon {
  height: 1em;
}
.custom-dropdown-input .PhoneInputCountrySelect {
  position: absolute;
  width: 100%;
  max-width: 20px;
  opacity: 0;
  cursor: pointer;
}
.custom-dropdown-input .PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.custom-dropdown-input.PhoneInput .PhoneInputInput {
  border: none;
  background: var(--white);
}
.planduration {
  height: 35px;
  width: 90px;
}
.dash-chart .graph {
  max-height: 340px;
  min-height: 340px;
  width: 100%;
}
.page-not-found img {
  max-width: 70%;
}
.something-wrong img {
  max-width: 45%;
}
.plan-view span {
  font-size: 0.8rem;
}
@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.full-page-loader img {
  animation: scaleAnimation 2s infinite;
}
.canvasjs-chart-title {
  font-weight: lighter !important;
}
.canvasjs-chart-credit {
  display: none;
}
.chats__content__header {
  min-height: 60px;
  padding: 0.5rem;
}
.bg--actionbox {
  border-radius: 8px;
  cursor: pointer;
  height: 2.1rem;
  min-height: 2.1rem;
  min-width: 2.1rem;
  width: 2.1rem;
}
.bg--light-grey {
  background: var(--dark-thin-gray);
  color: var(--light-black);
  font-weight: 500;
}
.chat_rightbar {
  max-height: calc(100vh - 5.75rem);
  min-height: calc(100vh - 5.75rem);
}
.chats__content__body {
  max-height: calc(100% - 112px);
}
.chats__content__body .or_items:after {
  border-bottom: 1px solid var(--light-gray);
  content: "";
  margin-bottom: 14px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.chats__content__body .or_items p {
  background-color: var(--light-gray);
  border-radius: 5em;
  font-size: 12px;
  padding: 0.6em 1.5em;
  z-index: 2;
}
.chats__content__body__incomming .bubble {
  background: var(--primary);
  border-radius: 0 18px 18px 18px;
  color: var(--white);
  line-height: 26px;
  margin-right: 5px;
  margin-top: 10px;
  padding: 6px 10px;
}

.chats__content__body__outgoing .bubble {
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 96%, 0.8),
    hsla(0, 0%, 96%, 0.8) 0.01%,
    hsla(0, 0%, 84%, 0.16)
  );
  border-radius: 18px 18px 0 18px;
  color: var(--light-black);
  line-height: 26px;
  margin-left: auto;
  margin-top: 10px;
  padding: 6px 10px;
}
.chats__content__body .bubble .message-item {
  font-size: 0.8rem;
  word-break: break-word;
}
.chats__content__footer__icon input {
  display: none !important;
}
textarea.form-control {
  height: auto;
}
.input-group-text {
  border-radius: 0px 5px 5px 0px !important;
  height: 34px;
}
.input-group-text1 {
  height: 38px !important;
}
.colorCircle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  padding: 1px;
}
.colorCircle span {
  min-height: 17px;
  min-width: 17px;
  border-radius: 100%;
}
.css-13cymwt-control {
  border-radius: 8px !important;
}
.paginationbox {
  white-space: nowrap;
  max-width: 4rem;
}
